

























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import StudentFaceIcon from '@/components/atoms/StudentFaceIcon.vue'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { RouterHelper } from '@/router/routerHelper'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type History = {
  sUnitId?: number // 小項目ID
  subject: { name: string; code: string } // 教科情報(教科名の表示と、生徒画面でのスタイル変更に使用する)
  content: string // 項目名
  date?: string // 学習時間
  count?: number // 学習回数
  rates: { rate: number; rateText: string }[] // 理解度
  contenLink?: string // TODO 遷移先リンク
}

@Component({
  components: {
    StudentFaceIcon,
    RatioBarText,
    ButtonBase,
  },
})
export default class StudentHistoryContent extends Mixins(StyleSubjectBackground) {
  @Prop()
  histories!: History[]

  @Prop({ default: false })
  isTeacher?: boolean

  @Prop({ default: '' })
  selectedSubject?: string

  // 学習履歴一覧へ遷移
  private studyUnitLink(curriculumSUnitId: number, modeCode = '') {
    const baseUrl = this.isTeacher ? `/teacher/v3/history/study-unit` : `/student/v3/history/study-unit`
    return `${baseUrl}/${curriculumSUnitId}?prevPage=${this.$route.name}&selectedSubject=${this.selectedSubject}&modeCode=${modeCode}`
  }

  get barStyleSet(): any {
    const _: any = this
    return function (code: string) {
      let fillColor = null
      let lineColor = null

      if (_.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = _.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }
}
