type ResultSummaryType = {
  subjectCode: string
  subjectName: string
  questionCount: number
  duration: number
}

function durationHourMinute(duration: number): { hour: number; minute: number } {
  return { hour: Math.floor(duration / 60), minute: duration % 60 }
}

export function FormatResultSummaries(resultSummaries: ResultSummaryType[]): object {
  const questionCount = resultSummaries.reduce((sum: number, resultSummary: ResultSummaryType) => {
    return sum + resultSummary.questionCount
  }, 0)
  const duration = resultSummaries.reduce((sum: number, resultSummary: ResultSummaryType) => {
    return sum + resultSummary.duration
  }, 0)
  const { hour, minute } = durationHourMinute(duration)
  const subjectDetails = {}
  const timeRate = {}
  const questionRate = {}
  resultSummaries.forEach((resultSummary: ResultSummaryType) => {
    const { hour, minute } = durationHourMinute(resultSummary.duration)
    subjectDetails[resultSummary.subjectCode] = {
      name: resultSummary.subjectName,
      questionCount: resultSummary.questionCount,
      hour: hour,
      minute: minute,
    }
    if (duration === 0) {
      // 時間が0の場合にグラフ表示を均等にするための値、ループ数で割るのでループ内でのみ使用可能
      timeRate[resultSummary.subjectCode] = Math.round(100 / resultSummaries.length)
    } else {
      timeRate[resultSummary.subjectCode] = Math.round((resultSummary.duration / duration) * 100)
    }
    if (questionCount === 0) {
      // 問題数が0の場合にグラフ表示を均等にするための値、ループ数で割るのでループ内でのみ使用可能
      questionRate[resultSummary.subjectCode] = Math.round(100 / resultSummaries.length)
    } else {
      questionRate[resultSummary.subjectCode] = Math.round((resultSummary.questionCount / questionCount) * 100)
    }
  })

  return {
    questionCount: questionCount,
    hour: hour,
    minute: minute,
    timeRate: timeRate,
    questionRate: questionRate,
    subjectDetails: subjectDetails,
  }
}
