












import { Component, Vue, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import { CurriculumsResult } from '@/models/api/v2/history/curriculums'

import TJContents from '@/components/organisms/v2/dashboard/TJContents.vue'

@Component({
  components: {
    SidebarSwitcher,
    TJContents,
  },
})
export default class Dashboard extends Mixins(QueryMethods, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  // cookieセット完了フラグ
  private cookieReady = false

  // クラスモード
  public classMode(): string {
    return this.CLASS_MODE.NORMAL
  }

  // クラスモード毎に表示する子コンポーネント
  private get contentsComponent() {
    return `${this.classMode()}Contents`
  }

  private get classModeName() {
    return this.classModeText(this.classMode())
  }

  private intervalId: any = 0

  private understandingTotal = {}
  private understandingHistories: object[] = []

  // 学習状況取得（補助教材以外全てのモード）
  private async loadResultSummaries() {
    await Vue.prototype.$http.httpWithToken
      .get(`/v2/history/resultSummaries/${this.userId}`, {
        params: { classModeCode: 'TJ,TK,KS,NY,MN,AI' },
      })
      .then((res: any) => {
        this.understandingTotal = FormatResultSummaries(res.data)
      })
  }

  // 直近の履歴を取得（通常、講習会、AIモードのみ）
  private async loadLatestCurriculum() {
    await Vue.prototype.$http.httpWithToken
      .get('/v2/history/resultDrills/latestCurriculum', {
        params: {
          userId: this.userId,
          classModeCode: 'TJ,KS,AI',
          classCategoryCode: 'RK,EN',
        },
      })
      .then((res: any) => {
        this.understandingHistories = res.data.map((curriculums: CurriculumsResult) => {
          const rateText = `${curriculums.predictedScoreMin}%-${curriculums.predictedScoreMax}%`
          const count = Object.values(curriculums.count).reduce((sum: number, cnt: number) => sum + cnt, 0)
          return {
            sUnitId: curriculums.sUnitId,
            subject: { name: curriculums.subjectName, code: curriculums.subjectCode },
            content: curriculums.sUnitName,
            date: curriculums.date,
            count: count,
            rates: [{ rate: curriculums.predictedScore, rateText: rateText }],
          }
        })
      })
  }

  private created(): void {
    this.setClassModeFromQuery()
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadResultSummaries()
    await this.loadLatestCurriculum()
    // 授業の開始情報は5分おきに取得する
    this.intervalId = setInterval(() => {
      // cookieの期限切れであれば生徒ログイン画面に遷移する
      if (!Vue.prototype.$cookies.get('dataGdls')) {
        this.$router.push({ name: 'StudentsYgcLogin' })
        return
      }
    }, 300000)
    Vue.prototype.$loading.complete()
  }

  private beforeDestroy() {
    clearInterval(this.intervalId)
  }

  /**
   * クラスモードをcookieにセット&APIリクエスト
   * Queryにてmode指定がある場合は優先する
   * ex. /student/dashboard?mode=TJ
   * Query指定が無い場合は既存のcookieの値を参照し、未設定の場合は通常モードとする
   */
  private async setClassModeFromQuery() {
    const classModeFromQuery = this.getParamsObject().mode
    if (this.isValidClassMode(classModeFromQuery)) {
      await this.changeClassMode(classModeFromQuery)
    } else {
      await this.changeClassMode(Vue.prototype.$cookies.get('dataGdls').sideMenuMode || this.CLASS_MODE.NORMAL)
    }
    this.cookieReady = true
  }
}
