
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Doughnut, mixins } from 'vue-chartjs'
import ChartFillPattern from '@/components/atoms/ChartFillPattern.vue'

@Component
export default class ChartDonutSubject extends Mixins(Doughnut, mixins.reactiveProp, ChartFillPattern) {
  @Prop()
  id?: string

  @Prop({ default: { su: 0, ei: 0, ko: 0, ri: 0, sh: 0 } })
  subjects!: { su: number; ei: number; ko: number; ri: number; sh: number }

  get chartId(): string {
    return this.id || `id${this.$uuid.v4()}`
  }

  private data!: Chart.ChartData

  private patterns: any[] = []

  private options: { [key: string]: any } = {
    animation: {
      animateRotate: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  }

  @Watch('subjects')
  onSubjectsChanged(newSubjects: { su: number; ei: number; ko: number; ri: number; sh: number }) {
    const { su, ei, ko, ri, sh } = newSubjects
    this.data = {
      labels: ['算数/数学', '英語', '国語', '理科', '社会'],
      datasets: [
        {
          data: [su, ei, ko, ri, sh],
        },
      ],
    }

    this.loadPatterns().then(() => {
      if (!this.data.datasets) return

      this.data.datasets[0].backgroundColor = this.patterns
      // ここで画像読み込み+設定
      this.renderChart(this.data, this.options)
    })
  }

  private loadPatterns(): any {
    // 背景パターンのデフォルト値と画像
    const patternTable = this.getPatternTable()
    let promise = Promise.resolve()
    for (let i = 0; i < patternTable.length; i++) {
      promise = promise.then(this.createPattern.bind(this, patternTable[i]))
    }
    return promise
  }

  private createPattern(pattern: { [key: string]: any }): any {
    return this.loadImage(pattern.src).then(
      (img: any) => {
        const canvas = document.getElementById(this.chartId) as HTMLCanvasElement
        const ctx = canvas?.getContext('2d')
        this.patterns.push(ctx?.createPattern(img, 'repeat'))
      },
      () => {
        this.patterns.push(pattern.default)
      }
    )
  }

  private loadImage(src: string): any {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = () => reject()
      img.src = src
    })
  }
}
