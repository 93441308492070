




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class StudentFaceIcon extends Vue {
  @Prop()
  faceNumber?: number

  @Prop({ default: false })
  enable?: boolean

  get classObject(): {} {
    return {}
  }

  get imageFile(): string {
    let file = ''
    switch (this.faceNumber) {
      case 1:
        file = '5-veryGood.png'
        break
      case 2:
        file = '4-Good.png'
        break
      case 3:
        file = '3-soso.png'
        break
      case 4:
        file = '2-Bad.png'
        break
      case 5:
        file = '1-veryBad.png'
        break
    }
    const directory = this.enable ? 'on' : 'off'
    return file ? `/img/face/${directory}/${file}` : ''
  }
}
