
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ChartFillPattern extends Vue {
  private patternTable = [
    { code: 'su', default: '#77BBFF', src: '/img/graph/student/su.png' },
    { code: 'ei', default: '#BC99CC', src: '/img/graph/student/ei.png' },
    { code: 'ko', default: '#EFAB56', src: '/img/graph/student/ko.png' },
    { code: 'ri', default: '#99BB55', src: '/img/graph/student/ri.png' },
    { code: 'sh', default: '#BB9988', src: '/img/graph/student/sh.png' },
  ]

  private patternTableTeacher = [
    { code: 'su', default: '#77BBFF', src: '/img/graph/teacher/su.png' },
    { code: 'ei', default: '#BC99CC', src: '/img/graph/teacher/ei.png' },
    { code: 'ko', default: '#EFAB56', src: '/img/graph/teacher/ko.png' },
    { code: 'ri', default: '#99BB55', src: '/img/graph/teacher/ri.png' },
    { code: 'sh', default: '#BB9988', src: '/img/graph/teacher/sh.png' },
  ]

  @Prop({ default: false })
  protected isTeacher?: boolean

  protected getPatternTable(): any[] {
    // 背景パターンのデフォルト値と画像
    return this.isTeacher ? this.patternTableTeacher : this.patternTable
  }

  protected getPattern(code: string): {} | undefined {
    const table = this.getPatternTable()
    let ret
    for (let i = 0; i < table.length; i++) {
      if (code === table[i].code) {
        ret = table[i]
      }
    }
    return ret
  }
}
