



































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ChartDonutSubject from '@/components/molecules/ChartDonutSubject.vue'
import ChartFillPattern from '@/components/atoms/ChartFillPattern.vue'
import StudentHistoryContent from '@/components/organisms/v2/StudentHistoryContent.vue'
import StudentHistoryContentV3 from '@/components/organisms/v3/StudentHistoryContent.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    ChartDonutSubject,
    StudentHistoryContent,
    StudentHistoryContentV3,
  },
})
export default class StudentDashboardUnderstanding extends Mixins(ChartFillPattern, ClassModeMethods) {
  @Prop()
  total!: {}

  @Prop({ default: [] })
  histories?: object[]

  @Prop()
  period?: { startAt: string; endAt: string; text: string }

  get componentStudentHistoryContent() {
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    return isV3 ? 'StudentHistoryContentV3' : 'StudentHistoryContent'
  }

  get styleSet(): any {
    const _: any = this
    return function (key: string) {
      return {
        '--background-image': 'url(' + _.getPattern(key).src + ')',
        '--background-size': _.isTeacher ? '50%' : 'cover',
      }
    }
  }
}
