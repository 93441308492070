
import { Component, Vue } from 'vue-property-decorator'

// mixinして使用する
// 検索条件などをクエリに一時保持する際に使用する
@Component
export default class QueryMethods extends Vue {
  /**
   * パラメータを除外したURLを返す
   * @return URL文字列
   */
  protected getUrlWithoutParams(): string {
    const url = new URL(window.location.href)

    return url.origin + url.pathname
  }
  /**
   * クエリ文字列を再設定したURLを返す
   * @param クエリに設定するオブジェクト(配列非対応)
   * @return URL文字列
   */
  protected createUrlAsParams(params: any): string {
    const url = new URL(this.getUrlWithoutParams())
    for (const key of Object.keys(params)) {
      url.searchParams.set(key, params[key])
    }

    return url.toString()
  }
  /**
   * クエリ文字列を再設定したURLを現在表示しているURLに設定する(破壊的)
   * @param クエリに設定するオブジェクト(配列非対応)
   */
  protected setUrlAsParams(params: any): void {
    history.replaceState('', '', this.createUrlAsParams(params))
  }
  /**
   * クエリ文字列をオブジェクトに変換して返す
   * @return クエリを変換したオブジェクト(key:value)
   */
  protected getParamsObject(): any {
    const url = new URL(window.location.href)

    const obj = {}
    for (const [key, value] of url.searchParams) {
      obj[key] = value
    }

    return obj
  }
}
